import React, { useEffect, useState } from "react"
import { Box, Text, Heading, Link, Fuegoicon, themeGet } from "@fuegokit/react"

import styled from "styled-components"

import NewsChip from "../NewsChip"
import FilterButton from "./FilterButton"
import SkeletonLoader from "../SkeletonLoader"
import { AfBoxExternalIcon } from "@fuegokit/fuegoicons-react"

const packageNames: { [key: string]: string } = {
  // we used to give these title case names, e.g., Fuegokit React, but
  // I think this format is more readable in the news feed -- M.O.
  "@fuegokit/gatsby-theme-fuegodocs": "@fuegokit/gatsby-theme-fuegodocs",
  "@fuegokit/react": "@fuegokit/react",
  "@fuegokit/fuegoicons": "@fuegokit/fuegoicons",
  "@fuegokit/prettier-config": "@fuegokit/prettier-config",
  "@fuegokit/fuegokit-eslint-config": "@fuegokit/fuegokit-eslint-config",
  "@fuegokit/codemod": "@fuegokit/codemod",
  "@fuegokit/tokens": "@fuegokit/tokens",
}

const now = new Date()
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const articleDate = (date: any) => {
  const day = new Date(date)
  return `${monthNames[day.getMonth()]} ${day.getDate()} ${
    now.getFullYear() > day.getFullYear() ? `, ${day.getFullYear()}` : ""
  }`
}

const articleDomain = (url: string) => {
  // return url.substring(url.indexOf("//") + 2, url.indexOf("/", 9))
  return url
}

const getDescription = (description: string) => {
  const maxWords = 30
  if (description) {
    const descriptionArray = description.split(" ")
    if (descriptionArray.length > maxWords) {
      return `${descriptionArray.slice(0, maxWords).join(" ")}...`
    } else {
      return description
    }
  }
}

const getTitle = (title: string, name: string, version: any) => {
  if (name && version) {
    return `${packageNames[name] || name} v${version}`
  }
  return title
}

export default function NewsList({ items, defaultFilter, ...rest }: any) {
  const [filter, updateFilter] = useState(defaultFilter)
  const setFilter = (value: any) =>
    updateFilter(filter === value ? null : value)

  const types = items.reduce((types: any, { type }: any) => {
    types.add(type)
    return types
  }, new Set())

  if (filter) {
    items = items.filter((item: any) => item.type === filter)
  }

  return (
    <>
      <Box mt={[5, 4, 4, 7]} {...rest}>
        <Text fontSize={2}>
          <Box
            display="flex"
            flexWrap="wrap"
            mb={[5, null, null, 3]}
            flexDirection={["column", "column", "row", "row"]}
          >
            <FilterButton
              mb={[4, 3, 3, 3]}
              mr={5}
              onClick={() => setFilter(null)}
              selected={filter === null}
            >
              All
            </FilterButton>
            {types
              ? Array.from<string>(types)
                  .sort()
                  .map((type: string, index: number) => {
                    return (
                      <FilterButton
                        mb={[4, 3, 3, 3]}
                        mr={5}
                        onClick={() => setFilter(type)}
                        key={`${type}-${index}`}
                        selected={filter === type}
                      >
                        {type &&
                          `${type.charAt(0).toUpperCase()}${type.slice(1)}`}
                        s
                      </FilterButton>
                    )
                  })
              : null}
          </Box>
        </Text>
        <Box>
          {items.map((item: any) => {
            if (item.type === "article") {
              // console.log("item.url: ", item.url)
            }
            return item.date && item.url ? (
              <Box mb={8}>
                <Link
                  target="_blank"
                  href={item.url}
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  <Heading
                    sx={{
                      fontSize: 3,
                      pb: 1,
                      lineHeight: 1.25,
                    }}
                  >
                    {getTitle(item.title, item.fullName, item.version)}
                  </Heading>
                </Link>
                <Box
                  sx={{
                    mt: 1,
                    mb: 3,
                    color: "text.default",
                    fontSize: 3,
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                  }}
                  maxWidth={`50ch`}
                >
                  <StyledText>
                    {" "}
                    {articleDate(item.date)} {` `}
                  </StyledText>

                  <NewsChip type={item.type}>{item.type}</NewsChip>
                  {` `}
                  <Link
                    target="_blank"
                    href={item.url}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      fontSize: 2,
                    }}
                  >
                    <span style={{ transform: "translateY(1px)" }}>Link</span>
                    <AfBoxExternalIcon size={`small`} />
                  </Link>
                </Box>
                <Box maxWidth="75ch">
                  <Text
                    as="p"
                    mb={1}
                    fontSize={3}
                    sx={{ color: "text.default" }}
                  >
                    {getDescription(item.description)}
                  </Text>
                </Box>
              </Box>
            ) : null
          })}
        </Box>
      </Box>
    </>
  )
}
const StyledText = styled(Text)`
  /* Area Normal has a baseline and descenders that requires us to manually offset */
  transform: translateY(2px) !important;
`
