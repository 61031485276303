import React from "react"
import styled, { keyframes } from "styled-components"

import { Box, Text, themeGet } from "@fuegokit/react"

const SkeletonLoader = ({ count }) => {
  return (
    <>
      {Array.from({ length: count }, (_, i) => (
        <>
          {/* <Text as="p" sx={{ color: "text.default" }}>
            Loading...
          </Text> */}
          <SkeletonWrapper>
            <HeadingSkeleton />
            <CopySkeleton />
            <CopySkeleton />
            <CopySkeleton />
          </SkeletonWrapper>
        </>
      ))}
    </>
  )
}

const shimmer = keyframes`
   0% {
        background-position: -1200px 0;
    }
    100% {
        background-position: 1200px 0;
    }
`

const SkeletonWrapper = styled(Box)`
  position: relative;
  overflow: hidden;
  margin-bottom: 32px;
`

const HeadingSkeleton = styled(Box)`
  height: 40px;
  margin-bottom: 8px;
  max-width: 256px;
  background-color: ${themeGet("colors.skeleton.default")};
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${shimmer};
  animation-timing-function: linear;

  background: linear-gradient(
    to right,
    #a1bdd914 8%,
    #00000000 18%,
    #a1bdd914 33%
  );
  background-size: 1200px 100%;
`

const CopySkeleton = styled(Box)`
  height: 24px;
  background-color: ${themeGet("colors.skeleton.default")};
  margin-bottom: 8px;
  max-width: 60ch;
  background-color: ${themeGet("colors.skeleton.default")};
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${shimmer};
  animation-timing-function: linear;

  background: linear-gradient(
    to right,
    #a1bdd914 8%,
    #00000000 18%,
    #a1bdd914 33%
  );
  background-size: 1200px 100%;
`

export default SkeletonLoader
