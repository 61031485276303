import React from "react"
import styled from "styled-components"
import { Box, BoxProps, themeGet } from "@fuegokit/react"

type FilterButtonType = React.PropsWithChildren<{
  selected?: boolean
}>

type FilterButtonProps = FilterButtonType &
  BoxProps &
  React.ComponentPropsWithRef<"a">

const FilterButton = ({ children, ...props }: FilterButtonProps) => {
  return (
    <StyledBox as="a" {...props}>
      <span className={"text"}>{children}</span>
    </StyledBox>
  )
}
const StyledBox = styled(Box)<FilterButtonProps>`
  display: flex;
  align-items: center;
  width: fit-content;
  text-transform: uppercase;
  font-size: ${themeGet("fontSizes.1")};
  background-color: ${themeGet("colors.background.neutral.subtle.default")};
  padding-left: ${themeGet("space.2")};
  padding-right: ${themeGet("space.2")};
  padding-top: ${themeGet("space.1")};
  padding-bottom: ${themeGet("space.1")};
  line-height: ${themeGet("lineHeights.default")};
  border-radius: ${themeGet("radii.2")};
  border: 1px solid ${themeGet("colors.border.subtle.default")};
  font-weight: 700;
  color: ${props =>
    themeGet(props.selected ? "colors.link.default" : "colors.text.default")};
  cursor: pointer;
  &:hover {
    color: ${props =>
      themeGet(
        props.selected ? "colors.text.selected" : "colors.text.default"
      )};
    text-decoration: "none";
    background-color: ${props =>
      themeGet(
        props.selected
          ? "colors.background.selected.default.[default]"
          : "colors.background.selected.default.hovered"
      )};
  }
  &.active {
    font-weight: ${themeGet("fontWeights.bold")};
    color: ${themeGet("colors.link.default")};
  }
  /* Area Normal has a baseline and descenders that requires us to manually offset */
  > span.text {
    vertical-align: initial;
    transform: translateY(1px);
  }
`
export default FilterButton
