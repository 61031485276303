import React, { useEffect, useState } from "react"

import { graphql } from "gatsby"
import { Box, Text, Heading } from "@fuegokit/react"

import ArticleLayout from "../components/ArticleLayout"
import NewsList from "../components/NewsList"
import posts from "../data/posts.yml"
import SkeletonLoader from "../components/SkeletonLoader"

const LATEST_RELEASE_API_URL = `https://fuegokit-releases.vercel.app/api/get-releases`
// @TODO - types
function NewsPage({ data }: any) {
  const {
    allFuegokitRelease: { nodes: releases },
  } = data

  const [newsItems, setNewsItems] = useState(releases)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setNewsItems(releases)
    setIsLoading(false)
  }, [releases])

  useEffect(() => {
    const newsItems = releases
      // tidy up release data for the Article component
      .map((release: any) => ({
        ...release,
        name: release.releaseName ? release.releaseName : null,
        type: "release",
        url: release.url,
        description: null,
        date: release.date,
      }))
      .concat(posts)
      .sort((a: any, b: any) => (b.date ? b.date.localeCompare(a.date) : null))
    setNewsItems(newsItems)
  }, [releases])

  return (
    <>
      <ArticleLayout>
        <Box
          sx={{
            bg: "elevation.surface.default.[default]",
            pb: 6,
          }}
        >
          <Box sx={{ color: "surface.default" }}>
            <Heading
              as="h1"
              sx={{
                color: "text.default",
                fontSize: 7,
                m: 0,
                mb: 2,
              }}
            >
              Fuegokit news
            </Heading>
            <Text as="p" sx={{ m: 0, color: "text.default", fontSize: 4 }}>
              News, releases, and updates from Fuegokit.
            </Text>
            {isLoading ? (
              <Box sx={{ mt: 4 }}>
                <SkeletonLoader count={newsItems.length} />
              </Box>
            ) : (
              <NewsList items={newsItems} />
            )}
          </Box>
        </Box>
      </ArticleLayout>
    </>
  )
}
export const query = graphql`
  query {
    allFuegokitRelease {
      nodes {
        id
        releaseName
        repo_slug
        semver {
          major
          minor
          patch
        }
        url
        version
        workspace
        date
        fullName
      }
    }
  }
`

export default NewsPage
