import React from "react"
import { Box, Text, themeGet } from "@fuegokit/react"
import styled from "styled-components"

interface NewsChipProps {
  type: "talk" | "release" | "workshop" | "article"
  children?: React.ReactNode
}
type ChipComponent = React.ComponentType<NewsChipProps>

const NewsChip = ({ type, children }: NewsChipProps) => {
  if (!type) return "Loading..."
  let Component: ChipComponent

  if (type === "talk") {
    Component = TalkChip
  } else if (type === "release") {
    Component = ReleaseChip
  } else if (type === "workshop") {
    Component = WorkshopChip
  } else if (type === "article") {
    Component = ArticleChip
  } else {
    throw new Error(
      `Unrecognized type, please designate a valid news item type.`
    )
  }

  return (
    <Component type={type}>
      <span className={"text"}>{children}</span>
    </Component>
  )
}

const TalkChip = styled(Box)<NewsChipProps>`
  background-color: ${themeGet("colors.background.accent.blue.bolder")};
  border-radius: ${themeGet("radii.2")};
  color: ${themeGet("colors.text.default")};
  display: "flex";
  font-size: ${themeGet("fontSizes.1")};
  padding-left: ${themeGet("space.1")};
  padding-right: ${themeGet("space.1")};
  line-height: ${themeGet("lineHeights.default")};
  text-transform: uppercase;
  letter-spacing: -0.01em;
  font-size: 1;
  font-weight: ${themeGet("fontWeight.semiBold")};
  /* Area Normal has a baseline and descenders that requires us to manually offset */
  /* Area Normal has a baseline and descenders that requires us to manually offset */
  > span.text {
    vertical-align: initial;
    transform: translateY(1px);
  }
`
const WorkshopChip = styled(Box)<NewsChipProps>`
  background-color: ${themeGet("colors.background.accent.purple.bolder")};
  border-radius: ${themeGet("radii.2")};
  color: ${themeGet("colors.text.inverse")};
  display: "flex";
  font-size: ${themeGet("fontSizes.1")};
  padding-left: ${themeGet("space.1")};
  padding-right: ${themeGet("space.1")};
  line-height: ${themeGet("lineHeights.default")};
  text-transform: uppercase;
  letter-spacing: -0.01em;
  font-size: 1;
  font-weight: ${themeGet("fontWeight.semiBold")};
  /* Area Normal has a baseline and descenders that requires us to manually offset */
  /* Area Normal has a baseline and descenders that requires us to manually offset */
  > span.text {
    vertical-align: initial;
    transform: translateY(1px);
  }
`

const ReleaseChip = styled(Box)<NewsChipProps>`
  background-color: ${themeGet("colors.background.accent.green.bolder")};
  border-radius: ${themeGet("radii.2")};
  color: ${themeGet("colors.text.inverse")};
  display: "flex";
  font-size: ${themeGet("fontSizes.1")};
  padding-left: ${themeGet("space.1")};
  padding-right: ${themeGet("space.1")};
  line-height: ${themeGet("lineHeights.default")};
  text-transform: uppercase;
  letter-spacing: -0.01em;
  font-size: 1;
  font-weight: ${themeGet("fontWeight.semiBold")};
  /* Area Normal has a baseline and descenders that requires us to manually offset */
  /* Area Normal has a baseline and descenders that requires us to manually offset */
  > span.text {
    vertical-align: initial;
    transform: translateY(1px);
  }
`

const ArticleChip = styled(Box)<NewsChipProps>`
  background-color: ${themeGet("colors.background.accent.blue.bolder")};
  border-radius: ${themeGet("radii.2")};
  color: ${themeGet("colors.text.inverse")};
  display: "flex";
  font-size: ${themeGet("fontSizes.1")};
  padding-left: ${themeGet("space.1")};
  padding-right: ${themeGet("space.1")};
  padding-top: 1px;
  line-height: ${themeGet("lineHeights.default")};
  text-transform: uppercase;
  letter-spacing: -0.01em;
  font-size: ${themeGet("fontSize.1")};
  font-weight: ${themeGet("fontWeight.semiBold")};
  /* Area Normal has a baseline and descenders that requires us to manually offset */
  vertical-align: initial;
  transform: translateY(1px);
`
export default NewsChip
